.space {
  margin-top: 32px;
}
.docs {
  margin-top: 15px;
}
.doc {
  text-decoration: underline;
}
.verification {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.kinto .login-form,
.derbygo .login-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: Trueno;
  color: #fff;
  margin-left: 15%;
  width: 75%;
}
@media screen and (max-width: 768px) {
  .kinto .login-form,
  .derbygo .login-form {
    width: 100%;
    height: 100%;
    padding: 24px 24px;
    margin: 0;
  }
}
.derbygo .login-form {
  color: #013565;
}
.kinto .login-text,
.derbygo .login-text {
  font: 28px/40px Trueno;
  width: 75%;
  letter-spacing: 0;
  margin-bottom: 10%;
  color: #fff;
}
.kinto .login-button,
.derbygo .login-button {
  width: 100%;
  padding: 5px 1px;
  margin: 5px 1px;
  text-transform: uppercase;
}
.kinto .password-reset-text,
.derbygo .password-reset-text {
  font-size: 80%;
  color: #fff;
  margin: 10px 0;
}
.derbygo .login-text,
.password-reset-text {
  color: #013565;
}
.kinto .login-button:hover,
.kinto .login-button:focus,
.kinto .login-button:active {
  border: 1px solid #fff;
  margin: 4px 0;
  padding: 5px 0;
}
.derbygo .login-button {
  width: 360px;
  max-width: 100%;
  margin: 10px auto;
}
.bp.password-reset-text {
  font-size: 80%;
  margin: 10px 0;
}
.login-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  background-position: right;
  height: 100vh;
}
.login-side {
  padding-top: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  opacity: 1;
  align-items: center;
}
.kinto .login-side {
  background: transparent linear-gradient(210deg, #319bb1 0%, #19829b 59%, #08708c 88%, #066d8a 100%) 0% 0% no-repeat padding-box;
}
.derbygo .login-side {
  background: #fff;
  padding-top: 5%;
  padding-bottom: 5%;
  align-items: center;
}
.derbygo .login-field {
  width: 360px;
  max-width: 100%;
  margin: 10px auto;
}
.login-img {
  margin-left: 15%;
}
.login-footer {
  margin-top: 15%;
  align-self: center;
  color: #fff;
  font-family: Trueno;
  width: 100%;
  padding: 0 25%;
  display: flex;
  justify-content: space-around;
  font-size: 12px;
}
@media screen and (max-width: 736px) and (min-width: 641px) {
  .kinto.login-img {
    margin: 0 auto;
    max-width: 160px;
  }
  .login-side {
    padding-top: 5px;
  }
  .kinto.login-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: Trueno;
    color: #fff;
    margin-left: 5%;
    width: 90%;
  }
  .kinto.login-text {
    font: 18px/24px Trueno;
    width: 90%;
    letter-spacing: 0;
    margin-bottom: 10%;
    color: #fff;
  }
  .kinto.login-button {
    font-size: 0.8rem;
  }
  .login-footer {
    margin-top: 10px;
    padding: 0;
  }
}
@media screen and (max-width: 640px) {
  .login-img {
    margin: 0 auto 40px auto;
  }
  .kinto.login-text {
    font: 20px/26px Trueno;
    width: 90%;
    letter-spacing: 0;
    margin-bottom: 10%;
    color: #000;
  }
  .login-grid {
    grid-template-columns: 1fr;
  }
  .login-side {
    display: grid;
    grid-template-rows: 2fr 5fr 1fr;
    grid-gap: 0;
    justify-content: stretch;
    align-items: stretch;
  }
  .login-img {
    align-self: center;
  }
  .login-mobile {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    justify-items: stretch;
    max-width: 100vw;
  }
  .kinto.login-form {
    background-color: #fff;
    color: #3b3b3b;
    border-radius: 15px 15px 0 0;
    width: 100%;
    height: 100%;
    padding: 10% 10%;
    margin: 0;
  }
  .login-footer {
    background-color: #fff;
    margin: 0;
    height: 100%;
    color: #086e89;
  }
  .login-button {
    color: #086e89;
    font-weight: 700;
  }
  .login-white {
    color: #fff;
  }
  .login-button:hover {
    background-color: #086e89;
    color: #fff;
  }
}
@media screen and (max-width: 330px) {
  .kinto.login-form {
    padding: 10% 2%;
  }
  .kinto.login-text {
    margin-left: 8%;
  }
  .kinto.login-field {
    margin: 0 4% 8px 4%;
  }
}
/*# sourceMappingURL=src/pages/login/index.css.map */